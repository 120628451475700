<template>
    <sdCards title="Auxiliary Charge Detail" class="extra-charge">
        <FormValidationWrap v-if="parentId || AuxiliaryChargeApi.length">
            <HorizontalFormStyleWrap>
                <a-form ref="formRefAuxiliaryCharge" name="dynamic_form_nest_item" :model="dynamicValidateForm"
                    layout="vertical">
                    <a-row v-for="(auxiliaryCharge, index) in AuxiliaryChargeApi" :key="auxiliaryCharge.id" :gutter="25">
                        <a-col :span="5">
                            <a-form-item :name="['auxiliaryCharges', index, 'value']" :rules="{
                                required: true,
                                message: 'Missing Amount ',
                            }">
                                <a-input-number :disabled="readOnlyMode" :style="{ width: '100%' }"
                                    v-model:value="auxiliaryCharge.value" placeholder="Amount" /> </a-form-item></a-col>
                        <a-col :span="15">
                            <a-form-item :name="['auxiliaryCharges', index, 'description']">
                                <a-input :disabled="readOnlyMode" :style="{ padding: '8px 11px' }"
                                    v-model:value="auxiliaryCharge.description" placeholder="Description " />
                            </a-form-item></a-col>
                        <a-col :span="3" class="extra-buttons" v-if="!readOnlyMode">
                            <sdFeatherIcons v-if="parentId" @click="addCharge(auxiliaryCharge)" type="check-circle" />
                            <sdFeatherIcons @click="removeAuxiliaryCharge(auxiliaryCharge)" type="minus-circle" />
                        </a-col>
                    </a-row>
                    <a-form-item>
                        <a-button type="dashed" block @click="addAuxiliaryCharge" v-if="!readOnlyMode">
                            <PlusOutlined />
                            Add auxiliary charge
                        </a-button>
                    </a-form-item>
                </a-form>
            </HorizontalFormStyleWrap>
            <b>{{ $t('auxiliaryCharge.total') }}: {{ totalCharge }}</b>
        </FormValidationWrap>
        <div v-else>
            <p>You can't add auxiliary charge</p>
        </div>
    </sdCards>
</template>
<script>
import { onMounted, ref, reactive, computed, toRefs, defineComponent, watch } from 'vue';
import { useStore } from 'vuex';
import {
    MinusCircleOutlined,
    CheckCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons-vue";
const AuxiliaryCharge = defineComponent({
    name: 'AuxiliaryCharge',
    props: {
        parentId: String,
        parentType: Number,
        readOnlyMode: Boolean,
    },
    setup(props, { emit }) {
        const { state, dispatch } = useStore();
        const AuxiliaryChargeApi = computed(() => state.AuxiliaryChargeApi.data);
        const { parentId, parentType } = toRefs(props);
        /// Extracharge 
        const formRefAuxiliaryCharge = ref();
        const dynamicValidateForm = reactive({
            auxiliaryCharges: [],
        });
        const removeAuxiliaryCharge = (item) => {
            if (item.id) {
                dispatch("auxiliaryChargeApiDataDelete", item);
                setTimeout(() => {
                    emit('chargeUpdated')
                }, 2000);
            } else {
                let index = AuxiliaryChargeApi.value.findIndex(
                    (element) => element.index === item.index
                );
                if (index !== -1) {
                    AuxiliaryChargeApi.value.splice(index, 1);
                }
            }
        };
        const addEmptyItem = () => {
            let newCharge = {};
            switch (parentType.value) {
                case "1":
                    newCharge = {
                        saleId: parentId.value,
                    }
                    break;
                case "2":
                    newCharge = {
                        purchaseId: parentId.value,
                    }
                    break;
                case "3":
                    newCharge = {
                        dealId: parentId.value,

                    }
                    break;
            }

            newCharge.parentType = parentType.value;
            newCharge.index = AuxiliaryChargeApi.value.length;
            newCharge.value = "";
            newCharge.id = undefined;
            AuxiliaryChargeApi.value.push(newCharge);
        };
        const addAuxiliaryCharge = () => {
            if (AuxiliaryChargeApi.value.length) {

                formRefAuxiliaryCharge.value
                    .validate()
                    .then(async () => {
                        addEmptyItem();
                    })
                    .catch((error) => {
                        console.log("error", error);
                    });
            } else {
                addEmptyItem();

            }

        };
        const addCharge = (item) => {
            formRefAuxiliaryCharge.value
                .validate()
                .then(async () => {
                    if (item.id) {
                        dispatch("auxiliaryChargeUpdateData", item);
                    } else {
                        dispatch("auxiliaryChargeSubmitData", item);
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                }).finally(() => {
                    setTimeout(() => {
                        emit('chargeUpdated')
                    }, 2000);
                });
        };

        dynamicValidateForm.auxiliaryCharges = computed(() =>
            AuxiliaryChargeApi.value && AuxiliaryChargeApi.value.length
                ? AuxiliaryChargeApi.value.map((auxiliaryChargeTerm, key) => {
                    return {
                        key: key + 1,
                        id: auxiliaryChargeTerm.id,
                        value: auxiliaryChargeTerm.value,
                        detail: auxiliaryChargeTerm.detail,
                        description: auxiliaryChargeTerm.description,
                    };
                })
                : []
        );
        const totalCharge = computed(() => {
            return dynamicValidateForm.auxiliaryCharges.reduce((accumulator, currentValue) => {
                accumulator += currentValue.value;
                return accumulator;
            }, 0);
        });
        // #endregion
        watch(() => parentId.value, (newVal) => {
            if (newVal) {
                const elementsWithoutId = dynamicValidateForm.auxiliaryCharges.filter(charge => !charge.id);
                elementsWithoutId.forEach(element => {
                    switch (parentType.value) {
                        case "1":
                            element.saleId = parentId.value;
                            break;
                        case "2":
                            element.purchaseId = parentId.value;
                            break;
                        case "3":
                            element.dealId = parentId.value;
                            break;
                    }
                    element.parentType = parentType.value;
                    dispatch("auxiliaryChargeSubmitData", element);
                });
            }
        });

        onMounted(() => {
            dispatch("emptyAuxiliaryChargeData")
        })
        return {
            formRefAuxiliaryCharge,
            dynamicValidateForm,
            AuxiliaryChargeApi,
            removeAuxiliaryCharge,
            addAuxiliaryCharge,
            addCharge,
            totalCharge,
            PlusOutlined,
            MinusCircleOutlined,
            CheckCircleOutlined,
        };
    },
});

export default AuxiliaryCharge;
</script>
<style lang="scss">
#app {
    .extra-charge {
        .extra-buttons {
            display: flex;
            justify-content: space-between;
            padding-top: 17px;
        }
    }
}
</style>
